import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { mobileMedia, pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

// TODO: Use react-truncate package when available

const useStyles = makeStyles(theme => ({
  mainContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  root: {
    position: 'relative',
    marginTop: pxToRem(80),
    marginBottom: pxToRem(80),
    [theme.breakpoints.down('md')]: {
      marginTop: pxToRem(48),
      marginBottom: pxToRem(48),
    },
    '& .rowWrapper': {
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        padding: pxToRem(0),
        marginTop: pxToRem(0),
        marginBottom: pxToRem(0),
      },
    },
    '& .imageWrapper': {
      paddingTop: 0,
      paddingBottom: 0,
      overflow: 'hidden',
      [mobileMedia]: {
        width: '100%',
        maxHeight: '300px',
        overflowY: 'hidden',
        ...theme.mixins.marginLeft(pxToRem(24)),
        ...theme.mixins.marginRight(pxToRem(24)),
      },
    },
    '& .display-picture': {
      position: 'relative',
      '& img': {
        display: 'block',
      },
    },
    '& .overlap-txt-wrap': {
      width: 'calc((100vw) * 0.4525)',
      minHeight: '250px',
      position: 'absolute',
      backgroundColor: props =>
        get(props, 'params.backgroundColor') || theme.palette.background.SandDuneLight,
      color: props =>
        get(props, 'params.textColor') || theme.mixins.RussianViolet(),
      ...theme.mixins.marginLeft('calc((100vw) * 0.4318)'),
      paddingTop: pxToRem(33),
      paddingBottom: pxToRem(33),
      paddingLeft: pxToRem(45),
      paddingRight: pxToRem(45),
      [theme.breakpoints.down('md')]: {
        position: 'relative',
        width: '100%',
        minHeight: '166px',
        padding: `${pxToRem(32)} ${pxToRem(24)}`,
        ...theme.mixins.marginLeft(pxToRem(24)),
        ...theme.mixins.marginRight(pxToRem(24)),
      },
      [theme.breakpoints.down('sm')]: {
        padding: `${pxToRem(24)}`,
      },
    },
    '& .overlay-flex': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    '& .segment-title': {
      position: 'absolute',
      bottom: 0,
      ...theme.mixins.marginLeft(pxToRem(26)),
      marginBottom: pxToRem(26),
      color: theme.palette.common.White,
      [theme.breakpoints.down('md')]: {
        marginBottom: pxToRem(10),
      },
    },
    '& .sec-title': {
      marginBottom: pxToRem(23),
      '& h5': {
        ...theme.palette.common.ellipsis(1),
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: pxToRem(14),
      },
    },
    '& .sec-description': {
      marginBottom: pxToRem(36),
      ...theme.palette.common.ellipsis(5),
      [theme.breakpoints.down('md')]: {
        marginBottom: pxToRem(14),
      },
    },
    '&.has-no-image': {
      '& .txt-wrap': {
        ...theme.mixins.paddingLeft(pxToRem(0)),
        maxWidth: 'none',
      },
      '& .rowWrapper': {
        ...theme.mixins.right(pxToRem(0)),

        '& .rowWrapper-before': {
          ...theme.mixins.right('50%'),
          transform: `translateX(${theme.mixins.translateX(50)}%)`,
        },
        '& .rowWrapper-after': {
          display: 'none',
        },
      },
      [theme.breakpoints.down('md')]: {
        '& .imageWrapper': {
          display: 'none',
        },
        '& .rowWrapper': {
          paddingTop: pxToRem(36),
          paddingBottom: pxToRem(36),
          '& .rowWrapper-before': {
            top: pxToRem(0),
          },
        },
        '& .txt-wrap': {
          paddingBottom: pxToRem(0),
        },
      },
    },
  },
  pictureWrap: {
    width: 'calc((100vw) * 0.639)',
    height: '462px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '218px',
    },
  },
  actionBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& .btn-link': {
      ...theme.mixins.marginRight(pxToRem(16)),
      marginBottom: pxToRem(16),
      [theme.breakpoints.down('md')]: {
        marginBottom: pxToRem(8),
        ...theme.mixins.marginRight(pxToRem(8)),
      },

      '& .btn-txt': {
        ...theme.palette.common.ellipsis(1),
      }
    },
  },
  rowReverse: {
    flexDirection: 'row-reverse',

    '& .overlap-txt-wrap': {
      ...theme.mixins.marginRight('calc((100vw) * 0.4318)'),
      ...theme.mixins.marginLeft('auto'),

      [theme.breakpoints.down('md')]: {
        position: 'relative',
        width: '100%',
        ...theme.mixins.marginLeft(pxToRem(24)),
        ...theme.mixins.marginRight(pxToRem(24)),
      },
    },

    '& .segment-title': {
      right: 0,
      ...theme.mixins.marginRight(pxToRem(34.2)),
      ...theme.mixins.marginLeft('auto'),
    }
  },
}));

export default useStyles;
